import { useRequest } from "services/request";
import { Spec, SpecListResult } from "./types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useStore from "useStore";
import { useCallback } from "react";

/**
 *  !!!!! REMOVE COUNT TO THE STORE !!!!!!
 */

export default function useSpecApi() {
  const changeSpec = useStore((state) => state.changeSpec);
  const { selected } = useStore((state) => state.spec);
  const { count, page, perPage } = useStore((state) => state.spec);

  const req = useRequest();
  const queryClient = useQueryClient();

  async function getList({
    page,
    pageSize = 8,
  }: {
    page: number;
    pageSize?: number;
  }): Promise<Spec[]> {
    const query = `page=${page + 1}&page_size=${pageSize}`;
    const data: SpecListResult = await req({
      url: `fields-schemas/?${query}`,
      withAuth: true,
    });

    changeSpec({ count: data.count });

    return data.results;
  }

  // async function getOne(id: number) {
  //   return req({ url: `fields-schemas/${id}/`, withAuth: true });
  // }

  const getOne = useCallback(
    async (id: number) => {
      return req({ url: `fields-schemas/${id}/`, withAuth: true });
    },
    [req]
  );

  const create = useMutation({
    mutationFn: async (name: string): Promise<Spec> => {
      const data = await req({
        url: `fields-schemas/`,
        body: { name },
        withAuth: true,
      });
      return data;
    },
    onSuccess(data) {
      const lastPage = Math.ceil((count + 1) / perPage);

      changeSpec({ page: lastPage - 1, selected: data.id }); //zero based

      queryClient.setQueryData(["specs", lastPage], (oldData: Spec[]) => [
        ...oldData,
        data,
      ]);
    },
  });

  const edit = useMutation({
    mutationFn: async (name: string): Promise<Spec> => {
      return await req({
        url: `fields-schemas/${selected}/`,
        body: { name },
        options: { method: "PATCH" },
        withAuth: true,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["specs", page + 1], (oldData: Spec[]) =>
        oldData.map((spec) =>
          spec.id === data.id ? { ...spec, name: data.name } : spec
        )
      );
    },
  });

  const remove = useMutation({
    mutationFn: async (id: number): Promise<number> => {
      await req({
        url: `fields-schemas/${id}/`,
        options: { method: "DELETE" },
        withAuth: true,
      });
      return id;
    },
    onSuccess: () => {
      const results = queryClient.getQueryData<Spec[]>(["specs", page + 1]);

      if (results.length > 1) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === "specs" &&
            (query.queryKey[1] as number) >= page + 1,
        });
      } else {
        changeSpec({ page: page - 1, count: count - 1 }); // zero based page
        queryClient.removeQueries({
          queryKey: ["specs", page + 1],
        });
      }
    },
  });

  const clone = useMutation({
    mutationFn: async (id: number): Promise<Spec> => {
      const data = await req({
        url: `fields-schemas/${id}/clone/`,
        options: { method: "POST" },
        withAuth: true,
      });
      changeSpec({ selected: id });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "specs" &&
          (query.queryKey[1] as number) >= page + 1,
      });
    },
  });

  return {
    page,
    getList,
    getOne,
    create,
    edit,
    remove,
    clone,
  };
}
